function attach(el, event, func, userdelay) {
  var throttle = false; // not throttled

  var debounce = false; // holder debounce

  var delay = userdelay || false; // Feature detection

  var passiveIfSupported = false;
  func(); // initialise function before adding event handlers

  try {
    window.addEventListener("test", null, Object.defineProperty({}, "passive", {
      get: function get() {
        passiveIfSupported = {
          passive: false
        };
      }
    }));
  } catch (err) {}

  var attachment = function attachment(e) {
    if (delay) {
      // throttle
      if (!throttle) {
        throttle = true;
        func(e);
        setTimeout(function () {
          return throttle = 0;
        }, delay);
      } // debounce


      clearTimeout(debounce);
      debounce = setTimeout(function () {
        func();
      }, delay);
    } else {
      func();
    }
  };

  event.split(' ').forEach(function (type) {
    if (type === 'scroll' && passiveIfSupported) {
      el.addEventListener(type, function (e) {
        return attachment(e);
      }, passiveIfSupported);
    } else {
      el.addEventListener(type, function (e) {
        return attachment(e);
      });
    }
  });
}

exports.attach = attach;
function containsClass(el, className) {
  return (' ' + el.className + ' ').indexOf(' ' + className + ' ') > -1;
}

exports.containsClass = containsClass;
function Event(event, params) {
  params = params || {
    bubbles: false,
    cancelable: false,
    detail: undefined
  };
  var evt = document.createEvent('CustomEvent');
  evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
  return evt;
}

exports.Event = Event;
function drawSVG(svg) {
  var paths = svg.querySelectorAll('circle, ellipsis, line, polygon, polyline, rect, path'); // Initialize

  for (var i = 0; i < paths.length; i++) {
    paths[i].style.strokeDasharray = paths[i].getTotalLength();
    paths[i].style.strokeDashoffset = paths[i].getTotalLength();
  } // Our draw function. 1 = erase, 2 = draw


  var draw = function draw(duration) {
    var dir = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

    for (var i = 0; i < paths.length; i++) {
      paths[i].style.transition = "stroke-dashoffset ".concat(duration / 1000, "s ease");
      paths[i].style.strokeDashoffset = paths[i].getTotalLength() * dir;
    }
  };

  svg.draw = function () {
    var duration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1000;
    return draw(duration, 2);
  };

  svg.erase = function () {
    var duration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1000;
    return draw(duration, 1);
  };
}

exports.drawSVG = drawSVG;
function each(selector, func) {
  var nodeList = document.querySelectorAll(selector);
  var nodeArray = [];

  for (var i = 0; i < nodeList.length; i++) {
    nodeArray.push(nodeList[i]);
  }

  if (func && typeof func === 'function') nodeArray.forEach(function (el, i) {
    return func(el, i);
  });
  return nodeArray;
}

exports.each = each;
function getTransformValues(el) {
  var matrix = window.getComputedStyle(el).transform; // Remove the brackets and matrix strings

  var transformValues = matrix.replace('matrix(', '').replace(')', '');
  var transformArray = transformValues.split(',');
  return {
    scaleX: parseFloat(transformArray[0]),
    skewY: parseFloat(transformArray[1]),
    skewX: parseFloat(transformArray[2]),
    scaleY: parseFloat(transformArray[3]),
    translateX: parseFloat(transformArray[4]),
    translateY: parseFloat(transformArray[5])
  };
}

exports.getTransformValues = getTransformValues;
function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function loopObject(object, func) {
  if (object && _typeof(object) === 'object') {
    for (key in object) {
      func(key, object[key]);
    }
  }
}

exports.loopObject = loopObject;
function nodeArray() {
  var nodeList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var array = [];

  for (var i = 0; i < nodeList.length; i++) {
    array.push(nodeList[i]);
  }

  return array;
}

exports.nodeArray = nodeArray;
function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function objectAssign(defaultSettings, userSettings) {
  for (var _key in defaultSettings) {
    if (userSettings[_key] !== undefined) {
      if (_typeof(defaultSettings[_key]) === "object" && _typeof(userSettings[_key]) === "object") {
        objectAssign(defaultSettings[_key], userSettings[_key]);
      } else {
        defaultSettings[_key] = userSettings[_key];
      }
    }
  }

  for (key in userSettings) {
    if (_typeof(defaultSettings[key]) === "object" && _typeof(userSettings[key]) === "object") {
      objectAssign(defaultSettings[key], userSettings[key]);
    } else {
      defaultSettings[key] = userSettings[key];
    }
  }

  return defaultSettings;
}

exports.objectAssign = objectAssign;
function offset(el) {
  var obj = {
    x: 0,
    y: 0
  };

  while (el) {
    obj.y += el.offsetTop;
    obj.x += el.offsetLeft;
    el = el.offsetParent;
  }

  return obj;
}

exports.offset = offset;
function parentWithClass(el, className) {
  var parent = el.parentNode;

  while (!parent.classList.contains(className)) {
    parent = parent.parentNode;
  }

  return parent;
}

exports.parentWithClass = parentWithClass;
function relativeTarget(target, relativeElement) {
  var node = target;
  var parent = node.parentNode;

  if (node !== relativeElement) {
    while (parent && parent !== relativeElement) {
      parent = parent.parentNode;
    }

    return parent === relativeElement;
  } else {
    return true;
  }
}

exports.relativeTarget = relativeTarget;
function stagger(arrayOfElements, func) {
  var delay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 500;
  var setDelay = delay;

  for (var i = 0; i < arrayOfElements.length; i++) {
    func(arrayOfElements[i], delay);
    delay += setDelay;
  }
}

exports.stagger = stagger;