import $           from 'jquery';
import slick       from 'slick-carousel';
import {TweenMax} from 'gsap';
import ScrollMagic from 'scrollmagic';
import 'ScrollMagic/scrollmagic/minified/plugins/animation.gsap.min.js';

const matchHeight = require('jquery-match-height');

// ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

$('.imageCtaBlock__content__block').matchHeight();

let controller = new ScrollMagic.Controller();
let $body      = $('body');
let $hbBody    = $('body.Hamburger');

let breakpoints = {
  'xxs': 480,
  'xs': 540,
  'sm': 640,
  'md': 768,
  'xmd': 968,
  'lg': 1024,
  'xl': 1280,
  'xxl': 1440,
}

headerSlider('#headerSlider');
hamburger('#hamburger');
subnav('.js-mainLink');
fadeIn('.js-fade, .contentBlock');

function hamburger(hamburger) {
    var $nav               = $('.header__nav__list'),
        $hamburger         = $(hamburger),
        $lineOne           = $hamburger.find('span:first-child'),
        $lineTwo           = $hamburger.find('span:nth-child(2)'),
        $lineThree         = $hamburger.find('span:last-child'),
        hamburgerAnimation = new TimelineMax({ paused: true }),
        canClick           = 1;

    hamburgerAnimation
        .to($lineOne, 0.3, { top: "50%" }, 'start')
        .to($lineThree, 0.3, { top: "50%" }, 'start')
        .set($lineTwo, { autoAlpha: 1 }, 'start')
        .set($lineTwo, { autoAlpha: 0 }, 'mid')
        .to($lineOne, 0.3, { rotation: 45 }, 'end')
        .to($lineThree, 0.3, { rotation: -45 }, 'end');

    $hamburger.on('click', function () {
        if (canClick) {
            canClick = 0;
            if (!$hamburger.hasClass('open')) {
                hamburgerAnimation.restart();
                $hamburger.addClass('open');
                $nav.addClass('open');
            } else {
                hamburgerAnimation.reverse();
                $hamburger.removeClass('open');
                $nav.removeClass('open');
            }
            setTimeout(function () {
                canClick = 1;
            }, 500);
        }
    })
};

function subnav(mainLink) {
    $(mainLink).each(function () {
        var $this    = $(this),
            $trigger = $this.find('.svg-subnav'),
            $subnav  = $this.next(),
            canClick = 1;
        if ($subnav.length) {
            $trigger.on('click', function (e) {
                if ($body.hasClass('Hamburger') || $(window).width() < 968) {
                    e.preventDefault();
                    if (canClick == 1) {
                        if (!$this.hasClass('open')) {
                            $this.addClass('open');
                            $subnav.slideDown(200);
                        } else {
                            $this.removeClass('open');
                            $subnav.slideUp(200);
                        }
                    }
                    canClick = 0;
                    setTimeout(function () {
                        canClick = 1;
                    }, 400);
                }
            });
        }

    });
}

$('[data-hover-trigger]').each(function () {
    var $this   = $(this),
        $target = $('[data-hover-target="' + $this.data('hover-trigger') + '"]');

    $this.on('mouseenter', function () {
        $target.addClass('hover');
    }).on('mouseleave', function () {
        $target.removeClass('hover');
    })

});

function headerSlider(headerSlider) {
    var $headerSlider        = $(headerSlider),
        $headerSliderContent = $headerSlider.find('.slide'),
        $headerSliderMedia   = $headerSlider.find('.header__slider__media, .videoBg'),
        $headerSliderBody    = $headerSlider.find('.header__content__body'),
        $headerSliderDots    = $('#headerSliderDots li'),
        duration             = $headerSlider.data('duration'),
        auto                 = true,
        parallax             = new TimelineMax(),
        // parallaxIntensity = $headerSlider.data('parallax') / 100 * 75;
        parallaxIntensity    = 75;

    if (duration == 0 || duration == undefined) {
        auto = false;
    }

    if ($headerSliderMedia.length >= 2) {
        $headerSlider.slick({
            dots          : true,
            arrows        : false,
            fade          : true,
            autoplay      : auto,
            autoplaySpeed : duration,
            adaptiveHeight: true
        });
        $('.slick-dots').prependTo('.header');
      }
    // } else if ($(window).height() >= $headerSlider.height()) {
        parallax
            .to($headerSliderContent, 1, { yPercent: parallaxIntensity }, 'start')
            .to([$headerSliderBody, $headerSliderMedia], 0.5, { autoAlpha: 0 }, 'start')
            .to($headerSliderMedia, 0.5, { scale: 1.2 }, 'start')
        var parallaxScene = new ScrollMagic.Scene({ triggerElement: this, triggerHook: 0, duration: "150%" })
            .setTween(parallax)
            // .addIndicators('tween', 'element')
            .addTo(controller);
    // }
}


function expansion($parent, $target) {
    $('.js-expander-watch').slideUp();
    if ($parent.hasClass('js-expander-open')) {
        $('.js-expander-open').removeClass('js-expander-open');
        $target.slideUp();
    } else {
        $('.js-expander-open').removeClass('js-expander-open');
        $parent.addClass('js-expander-open');
        $target.slideDown();
    }
    ;
}

function expander(parent) {
    $(parent).each(function () {
        var $parent  = $(this),
            bp       = $parent.data('expander'),
            $trigger = $parent.find('.js-expander-trigger'),
            $target  = $parent.find('.js-expander-watch').first(),
            canClick = 1;

        $trigger.on('click', function (e) {
            e.preventDefault();
            if (canClick) {
                canClick = 0;
                if (bp == undefined || bp == '' || bp == null) {
                    expansion($parent, $target);
                } else {
                    if ($(window).width() < breakpoints[bp]) {
                        expansion($parent, $target);
                    }
                    ;
                }
                setTimeout(function () {
                    canClick = 1;
                }, 400);
            }
        });
    });
}

expander('.js-expander');

function toModal(element) {
    $(element).on('click', function (e) {
        if (e.target.tagName !== 'A') {
            var $this     = $(this),
                $template = $this.clone();
            $('.modal').remove();
            $template
                .appendTo('body')
                .addClass('js-modal-item')
                .wrap(
                    `<div class="modal closeModal">
					<div class="verticalAlign closeModal"></div>
				</div>`
                )
                .append(
                    `<button id="closeModal" class="closeModal">
					<span class="closeModal"></span>
					<span class="closeModal"></span>
				</button>`
                )

            $('.modal').on('click', function (e) {
                if ($(e.target).hasClass('closeModal')) {
                    $(this).remove();
                }
            })
        }
    });

}

toModal('.js-to-modal');

function modalVideo(element) {
    $(element).on('click', function () {
        var $this = $(this),
            video = {};

        video.src = $this.data('src')[0];
        video.id  = $this.data('src')[1];

        if (video.src == 'youtube') {
            video.template = $(`<iframe src="https://www.youtube.com/embed/${video.id}?modestbranding=1&rel=0&autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>`);
        } else if (video.src == 'vimeo') {
            video.template = $(`<iframe src="https://player.vimeo.com/video/${video.id}?autoplay=1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`);
        } else {
            console.log('There is an error with the source of the video. Please make sure to specify if this video comes from youtube or vimeo in your data-src');
            return false;
        }

        $('.modal').remove();

        $(video.template)
            .appendTo('body')
            .addClass('js-modal-item')
            .wrap(
                `<div class="modal closeModal">
					<div class="verticalAlign closeModal">
						<div class="row">
							<div class="column">
								<div class="responsiveEmbed"></div>
							</div>
						</div>
					</div>
				</div>`
            )

        $('.modal').on('click', function (e) {
            if ($(e.target).hasClass('closeModal')) {
                $(this).remove();
            }
        })
    });
}

modalVideo('.js-modal-video');

function loadVideo(element) {
    $(element).each(function () {
        var $this = $(this),
            video = {},
            $videoTemplate;

        video.src = $this.data('load-video')[0];
        video.id  = $this.data('load-video')[1];

        if (video.src == 'youtube') {
            video.template = $(`<iframe class="header__slider__media video" src="https://www.youtube.com/embed/${video.id}?autoplay=1&rel=0&controls=0&playsinline=1&modestbranding=1&showinfo=0&mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>`);
        } else if (video.src == 'vimeo') {
            video.template = $(`<iframe class="header__slider__media video" src="https://player.vimeo.com/video/${video.id}?autoplay=1&muted=1&playsinline=1&title=0&loop=1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`);
        } else {
            console.log('There is an error with the source of the video. Please make sure to specify if this video comes from youtube or vimeo in your data-src');
            return false;
        }

        $videoTemplate = $(video.template);

        $videoTemplate.appendTo($this);
        TweenMax.to($videoTemplate, 2, { autoAlpha: 1 });
    })
}

if ($(window).width() >= 1024) {
    loadVideo('.header [data-load-video]');
} 

function tabs(parent) {
    $(parent).each(function () {
        var $parent = $(this),
            $tab    = $parent.find('.js-tab-link'),
            $panel  = $parent.find('.js-tab-panel'),
            $video  = $panel.find('iframe');

        if ($video) {
            $video.each(function () {
                $(this).wrap(`<div class="responsiveEmbed"></div>`)
            });
        }

        $tab.on('click', function (e) {
            e.preventDefault();
            var $this     = $(this),
                $relPanel = $($this.data('target'));

            if (!$this.hasClass('js-active')) {
                // Reset Others
                $tab.removeClass('js-active');
                $panel.removeClass('js-active');
                // Apply Styles
                $this.addClass('js-active');
                $relPanel.addClass('js-active');
            }
        })
    })
}

tabs('.js-tabs');

function fadeIn(element) {
    $(element).each(function () {
        var $this    = $(this),
            $target  = $this.find('> *'),
            fade     = new TimelineMax(),
            duration = 1.5;

        fade.to($target, duration, { autoAlpha: 1, top: 0 });

        var fadeScene = new ScrollMagic.Scene({ triggerElement: this, triggerHook: .75, reverse: false })
            .setTween(fade)
            // .addIndicators('tween', 'element');
            .addTo(controller);
    })
}

/*------------------------------------------------------------------
 Sticky Header
 ------------------------------------------------------------------*/

let didScroll;
let $header = $('.header__nav');

if ($header.length > 0) {
    document.addEventListener('wheel', () => {
        didScroll = true;
        fixedHeader();
    }, {
        capture: true,
        passive: true
    });
}

setInterval(function () {
    fixedHeader();
}, 500);

function fixedHeader() {
    if ($(window).scrollTop() >= 200) {
        $header.addClass('colouredHeader');
    } else {
        $header.removeClass('colouredHeader');
    }
}


$('.wysiwygBlock__content table').each(function () {
    $(this).wrap('<div class="js-table"></div>');
});

function interchange($element, srcArray) {
  if ($(window).width() <= breakpoints.md && $element.attr('src') != srcArray[0]) {
    if ($element.is('img')) {
      $element.attr('src', srcArray[0]);
    }else {
      $element.css({
        'background-image': `url('${srcArray[0]}')`
      })
    }
  }

  else if ($(window).width() > breakpoints.md && $element.attr('src') != srcArray[1]) {
    if ($element.is('img')) {
      $element.attr('src', srcArray[1]);
    }else {
      $element.css({
        'background-image': `url('${srcArray[1]}')`
      })
    }
  }
  
}

$('[data-interchange]').each(function(){
  let $this = $(this);
  let srcArray = $this.data('interchange').split(', ');

  interchange($this, srcArray);

  $(window).on('resize', function(){
    interchange($this, srcArray);
  })
})

// $('.tingle-content-wrapper').children().each(function() {
//     let $this = $(this);
//     let $next = $this.next();
    
//     if ($next) {
//         console.log($this.css('background-color'));
//         if ($this.css('background-color') === $next.css('background-color')) {
//             $next.css('margin-top', '0');
//             $next.css('padding-top', '0');
//         }
//     }
// });